:root {
  --primary-color: #00C853;
  --secondary-color: #003087;
  --text-color: #333;
  --background-color: #f5f5f5;
}

/* Estilos generales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header y navegación */
header {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.logo {
  height: 40px;
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-left: 1.5rem;
}

nav ul li a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
}

nav ul li a:not(.btn-donate) {
  position: relative;
}

nav ul li a:not(.btn-donate)::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: var(--primary-color);
  transition: width 0.3s ease;
}

nav ul li a:not(.btn-donate):hover::after {
  width: 100%;
}

#lang-switch {
  padding: 0.3rem 0.6rem;
  border: 1px solid var(--text-color);
  border-radius: 3px;
  transition: all 0.3s ease;
}

#lang-switch:hover {
  background-color: var(--text-color);
  color: white;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger {
  display: block;
  width: 30px;
  height: 3px;
  background-color: var(--text-color);
  position: relative;
  transition: background-color 0.3s;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--text-color);
  transition: transform 0.3s;
}

.hamburger::before {
  top: -8px;
}

.hamburger::after {
  bottom: -8px;
}

/* Página de inicio */
#hero {
  text-align: center;
  padding: 4rem 1rem;
  margin-top: 60px;
}

#hero h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

#hero p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

#features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 3rem 1rem;
}

.feature {
  text-align: center;
  max-width: 300px;
  margin-bottom: 2rem;
}

.icon-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem;
}

.icon-circle i {
  font-size: 2rem;
  color: white;
}

.sect-icons{
  width: 3em;
}

#cta {
  text-align: center;
  padding: 3rem 1rem;
  background-color: var(--secondary-color);
}

.btn-primary {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.4rem;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #00A844;
}

/* Sección de precios */
#pricing {
  padding: 2.5rem 2rem;
  text-align: center;
}

.price-table {
  width: 100%;
  max-width: 600px;
  margin: 1rem auto;
  border-collapse: collapse;
}

.price-table th, .price-table td {
  border: 1px solid #ddd;
  padding: 0.5rem;
}

.price-table th {
  background-color: var(--primary-color);
  color: white;
}

.pricing-explanation {
  max-width: 800px;
  margin: 1rem auto;
  text-align: left;
  padding: 0 1rem; 
}

.pricing-explanation ul {
  list-style-type: none;
  padding-left: 0;
}

.pricing-explanation li {
  margin-bottom: 1rem;
}

.pricing-explanation li strong {
  color: var(--primary-color);
}

#payments h2 {
  padding-top: 0;
  margin-bottom: 0.8em;  
}

#payments {
  padding: 0.1rem 1rem 3rem; 
  background-color: var(--background-color);
  text-align: center; 
}

#payments p {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.6;
}

/* Página de música */
.music {
  padding-top: 6rem; 
}

#music-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

#music-list {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.track-header, .track-item {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 2fr 3fr;
  align-items: center;
  padding: 10px 15px;
  color: var(--text-color);
}

.track-header {
  background-color: #f0f0f0;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 1;
}

.track-item {
  border-bottom: 1px solid #eee;
}

.track-item:last-child {
  border-bottom: none;
}

.track-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
.play-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  font-family: 'Material Symbols Outlined';
  font-size: 24px;
  color: var(--primary-color);
}

.play-btn .material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-size: 24px;
  color: var(--primary-color);
}

.download-btn {
  padding: 5px 10px;
  margin-left: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s;
  background-color: var(--primary-color);
  color: white;
}

.download-btn.mp3 {
  background-color: var(--primary-color);
  color: white;
}

.download-btn.wav {
  background-color: var(--secondary-color);
  color: white;
}

.download-btn.premium {
  background-color: #FFA500;
  color: black;
}

.download-btn:hover, .play-btn:hover {
  opacity: 0.8;
}

.premium-label {
  font-size: 0.8em;
  color: #FFA500;
  background-color: transparent;
  padding: 2px 5px;
  border-radius: 3px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Controles de música */
.music-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filter-button {
  margin-bottom: 20px;
  padding: 9px 19px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
}

.sort-container {
  display: flex;
  align-items: center;
}

.sort-container label {
  margin-right: 10px;
}

.sort-container select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Filtros */
.filter-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.filter-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 1200px;
  max-height: 80vh;
  overflow-y: auto;
}

.filter-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.filter-group {
  flex: 1;
  min-width: 200px;
}

.filter-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-right: 10px;
  white-space: nowrap;
}

.checkbox-label input {
  margin-right: 5px;
}

.filter-group select {
  width: 100%;
  padding: 5px;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.filter-actions button {
  padding: 5px 15px;
  margin-left: 10px;
  cursor: pointer;
}

.filter-actions button:first-child {
  background-color: var(--primary-color);
  color: white;
}

.filter-actions button:last-child {
  background-color: #ccc;
}

/* Paginación */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button.active {
  background-color: var(--secondary-color);
  font-weight: bold;
}

.pagination span {
  font-weight: bold;
}

/* Sección de publicidad */
#ad-space {
  background-color: #f0f0f0;
  padding: 1rem;
  margin: 1rem 0;
  text-align: center;
}

.ad-label {
  font-family: monospace;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

/* Página de cómo usar */
#como-usar {
  padding: 3rem 1rem;
  background-color: #f9f9f9;
}

.terms-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.terms-container ol {
  list-style-type: none;
  padding-left: 0;
}

.terms-container li {
  margin-bottom: 1.5rem;
}

.terms-container h3 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.terms-container ul {
  padding-left: 1.5rem;
}

.terms-container p {
  margin-left: 1.5rem;
}

.how-to-use h1 {
  margin-top: 3em;
  margin-bottom: 1em;
}

/* Página de política de privacidad */
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.privacy-policy h1 {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 30px;
}

.privacy-policy h2 {
  color: var(--secondary-color);
  margin-top: 30px;
  margin-bottom: 15px;
}

.privacy-policy p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.privacy-policy section {
  margin-bottom: 30px;
}

/* Página de términos y condiciones */
.terms {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: var(--text-color);
}

.terms h1 {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5em;
}

.terms section {
  margin-bottom: 30px;
}

.terms h2 {
  color: var(--secondary-color);
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 1.8em;
}

.terms p {
  margin-bottom: 15px;
}

.terms ul {
  padding-left: 20px;
  margin-bottom: 15px;
}

.terms li {
  margin-bottom: 10px;
}

/* Footer */
footer {
  background-color: var(--secondary-color);
  color: white;
  text-align: center;
  padding: 2rem 1rem;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.footer-links > * {
  margin: 0 0.5rem;
  color: white;
  text-decoration: none;
}

.footer-links a {
  color: white;
  text-decoration: none;
  margin: 0 0.5rem;
}

 /* Media queries */
@media (max-width: 768px) {
  nav ul {
      display: none;
  }

  #features {
      flex-direction: column;
      align-items: center;
  }

  .menu-toggle {
      display: block;
  }

  .nav-menu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: white;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .nav-menu.active {
      display: block;
  }

  .nav-menu li {
      margin: 0;
      text-align: center;
  }

  .nav-menu li a {
      display: block;
      padding: 1rem;
  }

  #lang-switch {
      border: none;
      padding: 1rem;
      background-color: transparent;
  }

  .track-header, .track-item {
      grid-template-columns: 1fr;
      gap: 5px;
  }

  .track-header {
      display: none;
  }

  .track-buttons {
      justify-content: flex-start;
  }

  .music-controls {
      flex-direction: column;
      align-items: stretch;
  }

  .music-controls > * {
      margin-bottom: 10px;
  }

  .sort-container {
      justify-content: space-between;
  }

  .filter-group {
      flex-basis: 100%;
  }

  .terms, .privacy-policy {
      padding: 15px;
  }

  .terms h1, .privacy-policy h1 {
      font-size: 2em;
  }

  .terms h2, .privacy-policy h2 {
      font-size: 1.5em;
  }

  .price-table {
      font-size: 0.9em;
  }

  .pricing-explanation {
      font-size: 0.95em;
  }

  .menu-icon {
      width: 2.7em;
      height: auto;
  }
}